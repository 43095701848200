<template>
  <b-form
    class="bg-white p-2"
    @submit.prevent
  >

    <!-- dane osobowe -->
    <h3 class="mb-2">
      Dane osobowe
    </h3>
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Imie"
          label-for="h-first-name"
          label-cols-md="4"
        >
          <b-form-input
            id="h-first-name"
            placeholder="Imiona"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Nazwisko"
          label-for="h-first-name"
          label-cols-md="4"
        >
          <b-form-input
            id="h-first-name"
            placeholder="Nazwisko"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Adres email"
          label-for=""
          label-cols-md="4"
        >
          <b-form-input
            id=""
            placeholder="Adres email"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Numer telefonu"
          label-for=""
          label-cols-md="4"
        >
          <b-form-input
            id=""
            placeholder="Numer telefonu"
          />
        </b-form-group>
      </b-col>

    </b-row>

    <!-- wiadomośc do wysłania -->

    <h3 class="mb-2">
      Wiadomość do wysłania
    </h3>
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Temat"
          label-for="h-first-name"
          label-cols-md="4"
        >
          <b-form-input
            id="h-first-name"
            placeholder="Prośba o wypelnienie  - kwestionariusz osobowy"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">

        <b-form-group
          label="Nagłówek"
          label-for="h-first-name"
          label-cols-md="4"
        >
          <b-form-textarea
            id="textarea-default"
            placeholder="Witamy, w związku z przygotowywaniem umowy przesyłamy do wypełnienia kwestionariusz danych osobowych, znajduje się on na tej stronie : link"
            rows="3"
          />
        </b-form-group>

      </b-col>

      <b-col>
        <b-form-group
          label=""
          label-for="h-first-name"
          label-cols-md="4"
        >

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Wyślij formularz
          </b-button>

        </b-form-group>

      </b-col>

    </b-row>

  </b-form>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormCheckbox, BFormSelect, BFormTextarea, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import WorkerAddress from './WorkerAddress.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BFormSelect,
    BFormTextarea,
    BButton,
    WorkerAddress,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected: ['A', 'B'],

    }
  },
}
</script>

<style>

</style>
